import React, { Component } from 'react'
import ReactHtml from 'react-html-parser'
import axios from 'axios';
import { connect } from "react-redux";
import { storeLanguages, storeMenu, storeLabels, setLanguage } from "../../actions/";
import Video from 'react-background-video-player';
import Sidebar from './Sidebar';
import Footer from '../Footer/Footer';
import Helmet from 'react-helmet';
const api = process.env.REACT_APP_GRAV_BASE_URL;

function mapDispatchToProps(dispatch) {
	return {
        storeLanguages: languages => dispatch(storeLanguages(languages)),
        storeMenu: menu => dispatch(storeMenu(menu)),
        storeLabels: labels => dispatch(storeLabels(labels)),
        setLanguage: language => dispatch(setLanguage(language)),
	};
}
export class ConnectedSingleProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: {},
      content: '',
      products: [],
      food_categories: [],
      feed_categories: [],
      labels: {},
      video: '',
      subProducts: [],
      outroduction: '',
      loading: true,
      description: '',
      
    }
  }
  sortProducts(products){
		let food = [],
		feed = [],
		food_categories = [],
		feed_categories = [],
		food_unique_categories = [],
		feed_unique_categories = [];
		food = products.filter((value)=>{
			return value.type === true;
		})	
		food_categories = food.map((item, key)=>{
			return item.category_label;
		})	
		let unique_food = [...new Set(food_categories)];
		for(let i = 0; unique_food.length > i; i++){
			let id =  [unique_food[i]]
			let item = products.filter((value)=>{
				return value.category_label === unique_food[i];
			})
			let menu = {
				products: item,
				name: id,
			}
			food_unique_categories.push(menu)		
		}
		feed = products.filter((value)=>{
			return value.type === false;
		})
		feed_categories = feed.map((item, key)=>{
			return item.category_label;
		})
		let unique_feed = [...new Set(feed_categories)];
		for(let i = 0; unique_feed.length > i; i++){
			let id =  [unique_feed[i]]
			let item = products.filter((value)=>{
				return value.category_label === unique_feed[i];
			})
			let menu = {
				products: item,
				name: id,
			}
			feed_unique_categories.push(menu)		
		}
		this.setState({
			food_categories: food_unique_categories,
			feed_categories: feed_unique_categories,
		})


	}
  componentDidUpdate(nextProps){
    window.scrollTo(0,0)
    if(this.props !== nextProps){
      this.fetchPage();
    }
  }
  componentDidMount() {
    window.scrollTo(0,0)
    this.fetchPage();

  }
  
  fetchPage(){
    let urlId = this.props.match.params.langid;
    this.props.setLanguage(urlId)
    let product = this.props.match.params.product;
    axios.get(`${api}/${urlId}/products/${product}.json`).then((res)=>{
      this.setState({
        product: res.data.content,
        labels: res.data.labels,
        products: res.data.products,
        subProducts: res.data.sub_products,
        outroduction: res.data.outroduction,
        description: res.data.description,
        loading: false,
        
      })
      if(res.data.video){
        this.setState({video: res.data.video})
      }
      this.sortProducts(res.data.products)
      if(res.data.labels){
        this.props.storeLabels(res.data.labels)
      }
      if(res.data.languages){
        this.props.storeLanguages(res.data.languages)
      }
      if(res.data.menu){
        this.props.storeMenu(res.data.menu)
      }
    })
    .catch(error => {
      if (error.response) {
        /*
        * The request was made and the server responded with a
        * status code that falls out of the range of 2xx
        */
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        this.props.history.push('/error/404')
      } else if (error.request) {
        /*
        * The request was made but no response was received, `error.request`
        * is an instance of XMLHttpRequest in the browser and an instance
        * of http.ClientRequest in Node.js
        */
        this.props.history.push(`/${urlId}/error/unknown-error`)
        console.log(error.request);
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log('Error', error.message);
        this.props.history.push(`/${urlId}/error/unknown-error`)
      }
      console.log(error.config);
    });
  }
  
  
  
  render() {
    const siteUrl = window.location.href;
    const {title, googletitle, googledesc, facebookimg} = this.state.product;
    const {subProducts} = this.state;
    const loaded = this.state.loading === false;
    return (
      <>
      
        {loaded&&
        <>
        <Helmet>
          <title>{`${title} | Laclat | Nurturing Global Trade`}</title>
          <meta name="description" content={googledesc} />
          <meta property="og:image:width" content="1000" />
          <meta property="og:image:height" content="730" />
          <meta property="og:url" content={siteUrl} />
          <meta property="og:title" content={googletitle}/>
          <meta property="og:title" content={googletitle}/>
          <meta name="twitter:title" content={googletitle}/>
          <meta property="twitter:title" content={googletitle}/>
          <meta property="og:description" content={googledesc} />
          <meta name="twitter:description" content={googledesc}/>
          <meta property="og:image" content={api + facebookimg} />
          <meta property="og:image:url" content={api + facebookimg}/>
          <meta name="twitter:image" content={api + facebookimg}/>
          <meta name="twitter:card" content={api + facebookimg}/>
        </Helmet>
      <div>
        <main>
			<div className="bg-primary relative">
				<div className="sm:pt-40 md:pt-56 lg:pt-64"><VideoBackground video={this.state.video}/></div>
				<div className="md:h-24 relative z-20"></div>
				<div className="md:py-20"></div>
			</div>
			<div>
				<div className="pageGrid">
          <div className="md:flex flex-wrap py-20">
            <div className="md:w-7/12">
              <h2 className="text-primary text-4xl mb-4 font-bold leading-snug">{this.state.product.title}</h2>
              <div className="pageArticle">
                {ReactHtml(this.state.description)}
                {subProducts&&
                  <SubProducts products={subProducts}/>
                }
                <div className=" pt-4 pb-8">
                  <hr className="block border-primary border-1"/>
                </div>
                {ReactHtml(this.state.outroduction)}
              </div>
            </div>
            <div className="md:w-1/12"></div>
            <div className="md:w-4/12">
              <Sidebar
                labels={this.state.labels}
                feed={this.state.feed_categories}
                food={this.state.food_categories}
              />
            </div>
          </div>
				</div>
			</div>
        </main>
      </div>
      <Footer/>
      </>
      }
      </>
    )
  }
}

const SingleProduct = connect(
	null,
	mapDispatchToProps
  )(ConnectedSingleProduct);
  
export default SingleProduct;
class VideoBackground extends Component {
	constructor(props) {
		super(props);
		this.state = {
			windowHeight: 0,
        	windowWidth: 0,
		}
	}
	componentDidMount() {
		this.checkDimensions();
		let video = document.getElementById('video');
		video.addEventListener('resize', this.checkDimensions.bind(this))
	}
	
	checkDimensions(){
    let video = document.getElementById('video');
		this.setState({windowWidth: video.innerWidth, windowHeight: video.pageYOffset})
	}
	render() {
		return (
			<div className="absolute top-0 left-0 w-screen h-full bottom-0 z-10" id="video">
				<Video
					containerWidth={this.state.windowWidth}
					containerHeight={this.state.windowHeight}
					src={`${api}${this.props.video}`}
					startTime={0}
					autoPlay={true}
          volume={0}
          
          
          
				/>
			</div>
		);
	}
}
export class SubProducts extends Component {
  render() {
    let mappedProducts = this.props.products.map((item, key)=>
      <div key={key}>
        <SubProduct title={item.title} description={item.description} image={item.image} heading={item.heading}/>
      </div>
    )
    return (
      <div>{mappedProducts}</div>
    );
  }
}

export class SubProduct extends Component {
  render() {
    const {image, heading} = this.props;
    return (
      <>
        <div>
          {heading&& <h3 className="text-primary mb-4 text-2xl" style={{fontSize: '22px'}}>{this.props.heading}</h3>}
          <h2>{ReactHtml(this.props.title)}</h2>
          <div className="my-8">
            {image?
              <div>
                <img src={api + this.props.image}  alt={this.props.title} className="max-w-full mb-4" style={{with: 'auto', height: 'auto'}}/>
                <div className="mt-8 mb-10">
                  {ReactHtml(this.props.description)}
                </div>
              </div>
              :
              <div className="mt-2 mb-8">{ReactHtml(this.props.description)}</div>
            }
          </div>
        </div>
      </>
    )
  }
}

// src/js/actions/index.js

import { 
  SET_LANGUAGE,
  STORE_LABELS,
  STORE_LANGUAGES,
  STORE_MENU,
} from "../constants/action-types";

export function setLanguage(payload) {
  return { type: SET_LANGUAGE, payload };
}
export function storeLabels(payload) {
  return { type: STORE_LABELS, payload };
}
export function storeLanguages(payload) {
  return { type: STORE_LANGUAGES, payload };
}
export function storeMenu(payload) {
  return { type: STORE_MENU, payload };
}

import React, { Component } from 'react'
import axios from 'axios';
import { connect } from "react-redux";
import { storeLanguages, storeMenu, storeLabels, setLanguage } from "../../actions/";
import ErrorImage from '../../Content/images/error.png';
const api = process.env.REACT_APP_GRAV_BASE_URL;
function mapDispatchToProps(dispatch) {
	return {
        storeLanguages: languages => dispatch(storeLanguages(languages)),
        storeMenu: menu => dispatch(storeMenu(menu)),
        storeLabels: labels => dispatch(storeLabels(labels)),
        setLanguage: language => dispatch(setLanguage(language)),
	};
}
export class ConnectedPageNotFound extends Component {
    componentDidMount() {
        let urlId = this.props.match.params.langid;
        axios.get(`${api}/${urlId}/api/menu.json`).then((res)=>{
            if(res.data.labels){
                this.props.storeLabels(res.data.labels)
              }
            if(res.data.languages){
                this.props.storeLanguages(res.data.languages)
            }
            if(res.data.languages){
                let activeLanguage = res.data.languages.filter((value)=>{
                    return value.active === true
                })
                if(activeLanguage.length > 0){
                    this.props.setLanguage(activeLanguage[0].name)
                }
            }
            if(res.data.menu){
                this.props.storeMenu(res.data.menu)
            }
        })
    }
    
    render() {
        return (
            <div className="h-screen bg-primary text-white w-full">
                <div className="flex items-center w-full text-center h-full">
                    <div>
                        <h1 className="text-6xl font-bold w-full mb-4">Error</h1>
                        <img src={ErrorImage} alt="" className="w-full mb-4 text-2xl"/>
                        <p>Sorry, there was an error processing that request</p>
                    </div>
                </div>
            </div>
        )
    }
}

const PageNotFound = connect(
	null,
	mapDispatchToProps
  )(ConnectedPageNotFound);
  
export default PageNotFound;

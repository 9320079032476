import React, { Component } from 'react'
import ReactHtml from 'react-html-parser'
import axios from 'axios';
import { connect } from "react-redux";
import { storeLanguages, storeMenu, storeLabels, setLanguage } from "../../actions/";
import Footer from '../Footer/Footer';
import {Link} from 'react-router-dom';
import HtmlParser from 'react-html-parser';
import Helmet from 'react-helmet';
const api = process.env.REACT_APP_GRAV_BASE_URL;

function mapDispatchToProps(dispatch) {
	return {
        storeLanguages: languages => dispatch(storeLanguages(languages)),
        storeMenu: menu => dispatch(storeMenu(menu)),
        storeLabels: labels => dispatch(storeLabels(labels)),
        setLanguage: language => dispatch(setLanguage(language)),
	};
}
export class ConnectedArticle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      articles: [],
      header: {},
      content: '',
      image: '',
      
    }
  }
  
  componentDidUpdate(nextProps){
    window.scrollTo(0,0)
    if(this.props !== nextProps){
      this.fetchPage();
    }
  }
  componentDidMount() {
    window.scrollTo(0,0)
    this.fetchPage();

  }
  
  fetchPage(){
    let urlId = this.props.match.params.langid;
    let article = this.props.match.params.article;
    this.props.setLanguage(urlId)
    axios.get(`${api}/${urlId}/news/${article}.json`).then((res)=>{
      this.setState({
        articles: res.data.articles,
        header: res.data.header,
        content: res.data.content,   
      })
      if(res.data.image){
        this.setState({
          image: res.data.image,    
        })
      }
      if(res.data.labels){
        this.props.storeLabels(res.data.labels)
      }
      if(res.data.languages){
        this.props.storeLanguages(res.data.languages)
      }
      
      if(res.data.menu){
        this.props.storeMenu(res.data.menu)
      }
    })
    .catch(error => {
      if (error.response) {
        /*
        * The request was made and the server responded with a
        * status code that falls out of the range of 2xx
        */
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        this.props.history.push('/error/404')
      } else if (error.request) {
        /*
        * The request was made but no response was received, `error.request`
        * is an instance of XMLHttpRequest in the browser and an instance
        * of http.ClientRequest in Node.js
        */
        this.props.history.push(`/${urlId}/error/unknown-error`)
        console.log(error.request);
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log('Error', error.message);
        this.props.history.push(`/${urlId}/error/unknown-error`)
      }
      console.log(error.config);
    });
  }
  
  
  
  render() {
    const siteUrl = window.location.href;
    const {title, googletitle, googledesc, facebookimg} = this.state.header;
    const {image} = this.state;
    return (
      <>
      <div>
      <Helmet>
        <title>{googletitle}</title>
        <meta name="description" content={googledesc} />
        <meta property="og:image:width" content="1000" />
        <meta property="og:image:height" content="730" />
        <meta property="og:url" content={siteUrl} />
        <meta property="og:title" content={googletitle}/>
        <meta property="og:title" content={googletitle}/>
        <meta name="twitter:title" content={googletitle}/>
        <meta property="twitter:title" content={googletitle}/>
        <meta property="og:description" content={googledesc} />
        <meta name="twitter:description" content={googledesc}/>
        <meta property="og:image" content={api + image} />
        <meta property="og:image:url" content={api + image}/>
        <meta name="twitter:image" content={api + image}/>
        <meta name="twitter:card" content={api + image}/>
      </Helmet>
        <main>
			<div className="bg-primary relative">
				
				<div className="h-24 relative z-20"></div>
				<div className="py-20"></div>
			</div>
			<div>
				<div className="pageGrid">
          <div className="md:flex flex-wrap py-20">
            <div className="md:w-8/12">
              <h1 className="text-primary text-4xl mb-4 font-bold leading-snug">{this.state.header.title}</h1>
              {image&&
                <img className="mb-6" src={api + this.state.image} alt={this.state.header.title}/>
              }
              <div className="pageArticle">{ReactHtml(this.state.content)}</div>
            </div>
            <div className="md:w-1/12"></div>
            <div className="md:w-3/12">
              <Sidebar labels={this.state.labels} articles={this.state.articles}/>
            </div>
          </div>
				</div>
			</div>
        </main>
      </div>
      <Footer/>
      </>
    )
  }
}


const Article = connect(
	null,
	mapDispatchToProps
  )(ConnectedArticle);
  
export default Article;





export class Sidebar extends Component {
    render() {
        let articles = this.props.articles.map((item, key)=>
            <div key={key} className="mb-4">
                <Link to={item.url}>
                <h2 className="font-bold text text-primary text-2xl leading-tight">{item.title}</h2>
                
                <p>{HtmlParser(item.summary)}</p>
                </Link>
            </div>
        )
        return (
            <div>
                {articles}
            </div>
        )
    }
}

import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import Html from 'react-html-parser';
export default class Sidebar extends Component {
    render() {
        let feed = this.props.feed.map((item, key)=>
            <div key={key} className="mb-2">
                <ProductList name={item.name[0]} products={item.products}/>
            </div>
        )
        let food = this.props.food.map((item, key)=>
            <div key={key} className="mb-2">
                <ProductList name={item.name[0]} products={item.products} id={key}/>
            </div>
        )
        return (
            <div className="p-5 pt-20 sideBar">
                <h2 className="text-primary capitalize text-3xl font-bold">
                    {this.props.labels.label_products}
                </h2>
                <h3 className="text-primary capitalize text-2xl font-bold mb-4 text-secondary">
                    {this.props.labels.label_food}
                </h3>
                {food}
                <h3 className="mt-5 text-primary capitalize text-2xl font-bold mb-4 text-secondary">
                    {this.props.labels.label_feed}
                </h3>
                {feed}
            </div>
        )
    }
}

export class ProductList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: 0,
        }
    }
    expandList(){

    }
    render() {
        let hasLinks = this.props.products.length > 1;
        let products = this.props.products.map((item, key)=>
        	<>
	            <li key={key} className="py-1 subProductListTrigger">
	            	<Link to={item.url} className="font-bold">{item.title}</Link>
	            	{item.sub_products&& <SubProductList products={item.sub_products} link={item.url} id={key+1}/>}
	            </li>
            </>
        )

        return (
            <ul>
               {hasLinks?
					<>
						<h4 className="font-semibold mb-2 text-primary">{this.props.name}</h4>
						<div className="text-blue-600 pl-5 text-sm">{products}</div>
					</>
					:
					<span className="font-semibold text-primary">{products}</span>
				}
            </ul>
        )
    }
}
export class SubProductList extends Component {
    
    
	render(){
		let list = this.props.products.map((item, key)=>
			<li className="mt-1">
				<Link to={this.props.link} className="text-sm font-normal text-blue-600">
					{Html(item.title)}
				</Link>
			</li>
		)
		return (
			<>
				<ul className="pl-2 my-2 subProductList">
					{list}
				</ul>
			</>
		)
	}
}
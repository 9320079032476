import React, { Component } from 'react'
import axios from 'axios';
import { connect } from "react-redux";
import Html from 'react-html-parser'
import { storeLanguages, storeMenu, storeLabels, setLanguage } from "../../actions/";
import Jorge from '../../Content/images/jorge.jpg';
import Footer from '../Footer/Footer';
import Helmet from 'react-helmet';
import Video from 'reactjs-videobg';
import ContactVideo from '../../Content/video/About_1.mp4'
import ServicesPoster from '../../Content/images/services.jpg'

const api = process.env.REACT_APP_GRAV_BASE_URL;
function mapDispatchToProps(dispatch) {
	return {
        storeLanguages: languages => dispatch(storeLanguages(languages)),
        storeMenu: menu => dispatch(storeMenu(menu)),
        storeLabels: labels => dispatch(storeLabels(labels)),
        setLanguage: language => dispatch(setLanguage(language)),
	};
}
export class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            labels: {},
            content: {},
            pageContent: '',
            agents: [],
            windowWidth: '',
            windowHeight: '',
            loading: true,
            header: {},
        }
    } 
    componentDidMount() {
      this.checkDimensions();
      window.addEventListener('resize', this.checkDimensions.bind(this))
      window.scrollTo(0,0)
      this.fetchPage();
    }
    componentDidUpdate(nextProps){
      if(this.props !== nextProps){
        this.fetchPage();
      }
    }
    checkDimensions(){
      this.setState({windowWidth: window.innerWidth, windowHeight: window.innerHeight})
  }
    fetchPage(){
      let urlId = this.props.match.params.langid;
      this.props.setLanguage(urlId)
      axios.get(`${api}/${urlId}/about-us.json`).then((res)=>{
        this.setState({
          content: res.data.header,
          labels: res.data.labels,
          menu: res.data.menu,
          languages: res.data.language,
          pageContent: res.data.content,
          agents: res.data.agents,
          loading: false,
        })
        if(res.data.labels){
          this.props.storeLabels(res.data.labels)
        }
        if(res.data.languages){ this.props.storeLanguages(res.data.languages)}
        
        if(res.data.menu){
          this.props.storeMenu(res.data.menu)
        }
      })
      .catch(error => {
        if (error.response) {
          /*
          * The request was made and the server responded with a
          * status code that falls out of the range of 2xx
          */
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          this.props.history.push('/error/404')
        } else if (error.request) {
          /*
          * The request was made but no response was received, `error.request`
          * is an instance of XMLHttpRequest in the browser and an instance
          * of http.ClientRequest in Node.js
          */
          this.props.history.push(`/${urlId}/error/unknown-error`)
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log('Error', error.message);
          this.props.history.push(`/${urlId}/error/unknown-error`)
        }
        console.log(error.config);
      });
    }
    render() {
        const {title, googletitle, googledesc, facebookimg} = this.state.content
        const siteUrl = window.location.href
       
        let agents = this.state.agents.map((item, key)=>
            <Agent agent={item} key={key}/>
        )
        let loaded = this.state.loading === false;
        return (
          <>
          {loaded&&
            <div>
            <Helmet>
              <title>{`${title} | Laclat`}</title>
	            <meta name="description" content={googledesc} />
	            <meta property="og:image:width" content="1000" />
	            <meta property="og:image:height" content="730" />
	           	<meta property="og:url" content={siteUrl} />
              <meta property="og:title" content={googletitle}/>
              <meta property="og:title" content={googletitle}/>
              <meta name="twitter:title" content={googletitle}/>
              <meta property="twitter:title" content={googletitle}/>
	            <meta property="og:description" content={googledesc} />
	            <meta name="twitter:description" content={googledesc}/>
	            <meta property="og:image" content={api + facebookimg} />
	        	  <meta property="og:image:url" content={api + facebookimg}/>
	        	  <meta name="twitter:image" content={api + facebookimg}/>
              <meta name="twitter:card" content={api + facebookimg}/>
            </Helmet>
            <div className="relative notFixed">
            <div className="bg-primary py-16 md:py-32 relative" >
                
                <div className="absolute top-0 left-0 w-screen h-full bottom-0 z-10" id="video">
                  <Video poster={ServicesPoster} autoPlay={true}><Video.Source src={ContactVideo} type="video/mp4" /></Video>
                </div>
               
                <div className="max-2xl mx-auto pageGrid text-left text-white z-30 relative">
                    <h1 className="text-3xl md:text-6xl font-bold md:py-20 text-center">{this.state.content.title}</h1>
                </div>
            </div>
            </div>
            <div className="py-20 relative z-30 bg-white">
                <div className="pageGrid">
                  <div className="md:flex flex-wrap">
                  <div className="md:w-6/12 about text-2xl">
                    {Html(this.state.pageContent)}
                  </div>
                  <div className="md:w-1/12"></div>
                  <div className="md:w-5/12">
                    <div className="md:pr-40">
                      <img src={Jorge} alt="Jorge Czar, Laclat Director" className="" width="1029" height="1029"/>
                    </div>
                    <div>
                      <a className="my-4 font-bold text-secondary inline-block" href="https://nz.linkedin.com/in/jorge-czar-8a21912a/en-us">
                        <strong className="text-2xl text-primary">Jorge Czar</strong><br/>{this.state.content.jorge_title}<br/>Laclat Limited</a>  
                        <p className="font-bold text-primary text-1xl"><i>{this.state.content.vision}</i></p>
                    </div>
                    
                  </div>
                  </div>
                  
                </div>
            </div>
            <div className="pt-12 pb-10 bg-gray-200" id="agents">
                <div className="pageGrid">
                    <div className="mb-6">
                      <h2 className="text-5xl text-secondary font-bold text-center">{this.state.content.network_heading}</h2>
                    </div>
                    <div className="block max-w-6xl mx-auto">
	                    <div className="flex flex-wrap w-full">
	                        {agents}
	                    </div>
                    </div>
                </div>
            </div>
            <Footer/>
            </div>
            }
            </> 
        )
    }
}

const ConnectedAbout = connect(
	null,
	mapDispatchToProps
  )(About);
  
export default ConnectedAbout;


export class Agent extends Component {
  render() {
    const {photo, phone, email} = this.props.agent
    return (
      <div className="p-4 w-full md:w-4/12 self-stretch">
        <div className="p-8 border rounded-lg shadow-sm bg-white self-stretch h-full">
        <div className="flex flex-wrap items-center w-full mb-4">
          <h2 className="w-full font-bold text-primary text-center text-3xl mb-2 ">
            {this.props.agent.region}
          </h2>
          {photo&&
          <div style={{borderRadius: '50%'}} className="overflow-hidden w-40 mx-auto border-4 shadow-lg border-secondary my-2">
            <img className="w-full" src={api + this.props.agent.photo} alt={this.props.agent.name + ' | ' + this.props.agent.company}/>
          </div>
          }
          <div className="w-full text-center mt-2">
            <h2 className="text-1xl font-bold leading-tight m-0 text-primary my-1">{this.props.agent.name}</h2>
            <h4 className="text-black font-bold text-md leading-tight my-2 mx-4">{this.props.agent.company}</h4>
            <h5 className="text-sm mx-5">{this.props.agent.location}</h5>
          </div>
        </div>
        <div class="py-0 text-center text-blue-500 text-sm">
        
          {email&&
          <div className="font-semibold">
            <span className="text-primary  flaticon-mail-2 mr-2"></span> 
            <a href={`mailto:${this.props.agent.email}`}>{this.props.agent.email}</a>
          </div>
          }
          {phone&&
          <div className="font-semibold">
            <span className="text-primary mr-2 flaticon-smartphone"></span>
            <a href={`tel:${this.props.agent.phone}`}>{this.props.agent.phone}</a>
          </div>
          }
        </div>
      </div>
      </div>
    )
  }
}
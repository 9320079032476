import React, { Component } from 'react'
import Logo from '../../Content/images/logo.png'
import { connect } from "react-redux";
import {Link} from 'react-router-dom';
import axios from 'axios';
import CallBackForm from './CallBackForm'
import Fern from '../../Content/images/Silver_fern_flag.svg'
const api = process.env.REACT_APP_GRAV_BASE_URL;
const mapStateToProps = (state, ownProps) => {
	return { 
		languages: state.languages, 
		menu: state.menu, 
		language: state.language, 
        labels: state.labels, 
        products: ownProps.products,
        short: ownProps.short,
	};
  };
const FooterConnected = ({ labels,menu,language,languages, short }) => (
	<Footer labels={labels} menu={menu} language={language} languages={languages} short={short}/>
);   
export class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            links: [],
            linksHeading: '',
            contact: {},
            policyMenu: [],
        }
    }
    componentDidMount() {
        if(this.props.language){
            axios.get(`${api}/${this.props.language}/useful-links.json`).then((res)=>{
                this.setState({links: res.data.links, linksHeading: res.data.heading, contact: res.data.contact, policyMenu: res.data.policy_menu}) 
            })
            .catch((err)=>console.log(err))
        }
    }
    componentDidUpdate(nextProps) {
        if(this.props.language !== nextProps.language){
            if(this.props.language){
            axios.get(`${api}/${this.props.language}/useful-links.json`).then((res)=>{
                this.setState({
                    links: res.data.links, 
                    linksHeading: res.data.heading, 
                    contact: res.data.contact, 
                    policyMenu: res.data.policy_menu
                }) 
            })
            .catch((err)=>console.log(err))
            }
        }
    }
    render() {
        let links = this.state.links.map((item, key)=>
            <li className="text-sm py-1" key={key}><a href={item.link} target="0_blank">{item.label}</a></li>
        )
        let menu = this.props.menu.map((item, key)=>
            <li className="text-sm py-1 inline-block w-full" key={key}><Link key={key} to={item.link}>{item.title}</Link></li>
        )
        let policyMenu = this.state.policyMenu.map((item, key)=>
        <li className="text-sm py-1 inline-block w-full" key={key}><Link to={item.link}>{item.title}</Link></li>
        )
        return (
            <>
            {this.props.short? null : <CallBackForm labels={this.props.labels}/>}
            
            <div className="bg-gray-900 text-white py-8">
                <div className="pageGrid p-2">
                    <div className="sm:text-left text-center md:flex w-full">
                        <div className="my-4 md:w-3/12">
                            <img src={Logo} alt="Laclat" className="w-40 mx-auto md:mx-0"/>
                            <div className="md:mt-20 py-4 text-secondary text-center md:text-left text-sm"><a href="/es">©</a> Laclat Limited {new Date().getFullYear()} </div>
                        </div>
                        <div className="my-4 md:w-3/12">
                            <div className="px-4">
                            <ContactInfo info={this.state.contact} labels={this.props.labels}/>
                            <img src={Fern} alt="New Zealand" className="block w-20 md:mt-20 text-center md:mx-0 mx-auto mt-10 block"/>
                            <p className="text-sm text-center md:text-left mb-10 md:mb-1">New Zealand</p>
                            </div>
                        </div>
                        <div className="my-4 md:w-3/12">
                            <div>
                                <div className="px-4 table mx-auto">
                                    <h4 className="capitalize font-bold text-secondary">
                                    {this.state.linksHeading}
                                    </h4>
                                    <ul>{links}</ul>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="my-4 md:w-3/12">
                            <div className="px-4 inline-block ml-auto">
                                <h4 className="capitalize font-bold text-secondary">
                                    {this.props.labels.label_menu}
                                </h4>
                                <ul>
                                    {menu}
                                    {policyMenu}
                                </ul>
                                <div>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
            </>
        )
    }
}
class ContactInfo extends Component {
    render() {
        let {contact_email, contact_postal, title} = this.props.info;
        return (
            <>
            {title&&<h4 className="font-bold text-secondary">{this.props.info.title}</h4>}
            <ul>
                {contact_email&&
                    <li className="text-sm mb-2"><span><a href={`mailto:${contact_email}`}>{contact_email}</a></span></li>
                }
                {contact_postal&&
                    <li className="text-sm mb-2"><span>{contact_postal}</span></li>
                }
            </ul>
            </>
        );
    }
}




const ReduxFooter = connect(mapStateToProps)(FooterConnected);

export default ReduxFooter;

import React, { Component } from 'react'
import axios from 'axios';
import { connect } from "react-redux";
import { storeLanguages, storeMenu, storeLabels, setLanguage } from "../../actions/";
import Footer from '../Footer/Footer';
import Helmet from 'react-helmet';
import Html from 'react-html-parser';
import Video from 'reactjs-videobg';
import ServicesVideo from '../../Content/video/services2.mp4';
import ServicesPoster from '../../Content/images/services.jpg'
const api = process.env.REACT_APP_GRAV_BASE_URL;

function mapDispatchToProps(dispatch) {
	return {
        storeLanguages: languages => dispatch(storeLanguages(languages)),
        storeMenu: menu => dispatch(storeMenu(menu)),
        storeLabels: labels => dispatch(storeLabels(labels)),
        setLanguage: language => dispatch(setLanguage(language)),
	};
}
export class ConnectedServices extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: 0,
            services: [],
            servicesContent: {
              services: [],
            },
            agents: [],
            loading: true,
        }
    }
      componentDidUpdate(nextProps){
        if(this.props !== nextProps){
          this.fetchPage();
        }
      }
      componentDidMount() {
        window.scrollTo(0,0)
        this.fetchPage();
    
      }
      fetchPage(){
        let urlId = this.props.match.params.langid;
        this.props.setLanguage(urlId)
        axios.get(`${api}/${urlId}/services.json`).then((res)=>{
          this.setState({
            servicesContent: res.data.services_page,
            services: res.data.servicesList,
            labels: res.data.labels,
            agents: res.data.agents,
            loading: false,
          })
          if(res.data.labels){
            this.props.storeLabels(res.data.labels)
          }
          if(res.data.languages){
            this.props.storeLanguages(res.data.languages)
          }
         
          if(res.data.menu){
            this.props.storeMenu(res.data.menu)
          }
        })
        .catch(error => {
          if (error.response) {
            /*
            * The request was made and the server responded with a
            * status code that falls out of the range of 2xx
            */
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            this.props.history.push('/error/404')
          } else if (error.request) {
            /*
            * The request was made but no response was received, `error.request`
            * is an instance of XMLHttpRequest in the browser and an instance
            * of http.ClientRequest in Node.js
            */
            this.props.history.push(`/${urlId}/error/unknown-error`)
            console.log(error.request);
          } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
            this.props.history.push(`/${urlId}/error/unknown-error`)
          }
        });
      }
    toggleDescription(index){
        this.setState({active: index})
    }
    render() {
        let services = this.state.services.map((item, key)=>
            <Service service={item} active={this.state.active} key={key} index={key} toggleDescription={this.toggleDescription.bind(this)}/>
        )
        const {title, googletitle, googledesc, facebookimg} = this.state.servicesContent;
        const siteUrl = window.location.href;
        const loaded = this.state.loading === false;
        return (
            <>
              {loaded&&
              <>
              <Helmet>
                <title>{`${title} | Lacalat | Nurturing Global Trade`}</title>
                <meta name="description" content={googledesc} />
                <meta property="og:image:width" content="1000" />
                <meta property="og:image:height" content="730" />
                <meta property="og:url" content={siteUrl} />
                <meta property="og:title" content={googletitle}/>
                <meta property="og:title" content={googletitle}/>
                <meta name="twitter:title" content={googletitle}/>
                <meta property="twitter:title" content={googletitle}/>
                <meta property="og:description" content={googledesc} />
                <meta name="twitter:description" content={googledesc}/>
                <meta property="og:image" content={api + facebookimg} />
                <meta property="og:image:url" content={api + facebookimg}/>
                <meta name="twitter:image" content={api + facebookimg}/>
                <meta name="twitter:card" content={api + facebookimg}/>
              </Helmet>
                <div className="bg-primary pt-24 relative notFixed overflow-hidden">
                  <div className="bg-black bg-opacity-50 w-screen h-screen absolute top-0 left-0 z-20"></div>
                  <div className="absolute top-0 left-0 w-screen h-full bottom-0 z-10" id="video">
                    <Video poster={ServicesPoster} autoPlay={true}><Video.Source src={ServicesVideo} type="video/mp4" /></Video>
                  </div>
                  <div className="pageGrid">
                      <div className="max-w-2xl md:py-32 lg:py-56 relative z-30">
                          <h1 className="text-secondary text-4xl font-bold">{this.state.servicesContent.title}</h1>
                          <p className="text-white text-2xl ">{this.state.servicesContent.short_description}</p>
                      </div>
                  </div>
                </div>
                <div className="py-20">
                    <div className="pageGrid">
                        <div className="max-w-5xl">
                            {services}
                        </div>
                    </div>
                </div>
                <Footer/>
                </>
                }
            </>
        )
    }
}


export class Service extends Component {
    
    render() {
        let active = this.props.active === this.props.index;
        return (
            <>
            <div className="items-start self-stretch flex my-3">
                <div className="triangle bg-secondary w-4 h-4 mr-4 mt-3"></div>
                <h3 className="text-2xl text-primary font-semibold">{this.props.service.heading}</h3>
            </div>
            <div className="pl-10 mb-12">
                <div className={active?
                        "duration-300 transition-all text-1xl text-primary transition opacity-100 h-auto"
                        :
                        "duration-300 transition-all text-1xl text-primary transition opacity-100 h-auto"
                    }
                >
                    {Html(this.props.service.description)}
                </div>
            
            </div>
            </>
        )
    }
}



const Services = connect(
	null,
	mapDispatchToProps
  )(ConnectedServices);
  
export default Services;
import React, { Component } from 'react'
import HeaderBanner from './HeaderBanner';
import axios from 'axios';
import { connect } from "react-redux";
import { storeLanguages, storeMenu, storeLabels, setLanguage } from "../../actions/";
import Helmet from 'react-helmet';
import Footer from '../Footer/Footer';
const api = process.env.REACT_APP_GRAV_BASE_URL;

function mapDispatchToProps(dispatch) {
	return {
        storeLanguages: languages => dispatch(storeLanguages(languages)),
        storeMenu: menu => dispatch(storeMenu(menu)),
        storeLabels: labels => dispatch(storeLabels(labels)),
        setLanguage: language => dispatch(setLanguage(language)),
	};
}
export class ConnectedHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
      products: [],
      productsPage: {},
      home: {},
      labels: {},
      menu:[],
    }
  }
  componentDidUpdate(nextProps){
    if(this.props !== nextProps){
      this.fetchPage();
    }
  }
  componentDidMount() {
    window.scrollTo(0,0)
    this.fetchPage();
    window.addEventListener('resize', this.checkDimensions.bind(this))
    this.checkDimensions()
  }
  checkDimensions(){
    this.setState({width: window.innerWidth, height: window.innerHeight});
  }
  fetchPage(){
    let urlId = this.props.match.params.langid;
    if(urlId === 'en' || urlId === 'pt' || urlId === 'es' || urlId === 'zh' || urlId === 'fr' || urlId === 'rs'){
    
    axios.get(`${api}/${urlId}/home.json`).then((res)=>{
      this.setState({
        home: res.data.home,
        labels: res.data.labels,
        menu: res.data.menu
      })
      if(res.data.labels){
        this.props.storeLabels(res.data.labels)
      }
      if(res.data.languages){
        this.props.storeLanguages(res.data.languages)
      }
      if(res.data.languages){
        let activeLanguage = res.data.languages.filter((value)=>{
          return value.active === true
        })
       
        if(activeLanguage.length > 0){
          this.props.setLanguage(this.props.match.params.langid)
        }
        
      }
      if(res.data.menu){
        this.props.storeMenu(res.data.menu)
      }
    })
    .catch(error => {
      if (error.response) {
        /*
        * The request was made and the server responded with a
        * status code that falls out of the range of 2xx
        */
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        this.props.history.push('/error/404')
      } else if (error.request) {
        /*
        * The request was made but no response was received, `error.request`
        * is an instance of XMLHttpRequest in the browser and an instance
        * of http.ClientRequest in Node.js
        */
        this.props.history.push(`/${urlId}/error/unknown-error`)
        console.log(error.request);
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log('Error', error.message);
        this.props.history.push(`/${urlId}/error/unknown-error`)
      }
      console.log(error.config);
    });
    }
  }
 
  render() {
    const siteUrl = window.location.href;
    const {title, googletitle, googledesc, facebookimg} = this.state.home;
    return (
      <>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={googledesc} />
          <meta property="og:image:width" content="1000" />
          <meta property="og:image:height" content="730" />
          <meta property="og:url" content={siteUrl} />
          <meta property="og:title" content={googletitle}/>
          <meta property="og:title" content={googletitle}/>
          <meta name="twitter:title" content={googletitle}/>
          <meta property="twitter:title" content={googletitle}/>
          <meta property="og:description" content={googledesc} />
          <meta name="twitter:description" content={googledesc}/>
          <meta property="og:image" content={api + facebookimg} />
          <meta property="og:image:url" content={api + facebookimg}/>
          <meta name="twitter:image" content={api + facebookimg}/>
          <meta name="twitter:card" content={api + facebookimg}/>
        </Helmet>
        <div>
        <HeaderBanner 
          heading={this.state.home.header_heading}
          description={this.state.home.header_description}
        />
        </div>
        <Footer short={true}/>
      </>
    )
  }
}


const Home = connect(
	null,
	mapDispatchToProps
  )(ConnectedHome);
  
export default Home;

import React, { Component } from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import { connect } from "react-redux";
import Footer from '../Footer/Footer';
import Helmet from 'react-helmet';
import Html from 'react-html-parser';
import Video from 'reactjs-videobg';
import HomeVideo from '../../Content/video/products_1.mp4'
import { storeLanguages, storeMenu, storeLabels, setLanguage } from "../../actions/";
const api = process.env.REACT_APP_GRAV_BASE_URL;

function mapDispatchToProps(dispatch) {
	return {
        storeLanguages: languages => dispatch(storeLanguages(languages)),
        storeMenu: menu => dispatch(storeMenu(menu)),
        storeLabels: labels => dispatch(storeLabels(labels)),
        setLanguage: language => dispatch(setLanguage(language)),
	};
}

export class AllProducts extends Component {
	constructor(props) {
		super(props);
		this.state = {
			products: {},
			food: [],
			feed: [],
			food_categories:[],
			feed_categories:[],
			page_content: {},
			labels: {},
			foodFilter: true,
			sectionChosen: true,
			loading: true,
		}
	}
	
	componentDidUpdate(nextProps){
		if(this.props !== nextProps){
		  this.fetchPage();
		}
	  }
	  componentDidMount() {
		window.scrollTo(0,0)
		this.fetchPage();
	
	  }
	  handleProductFilter(){
		  let status = this.state.foodFilter;
		  window.scrollTo(0,0);
		  this.setState({foodFilter: !status});
	  }
	  sortProducts(products){
		let food = [],
		feed = [],
		food_categories = [],
		feed_categories = [],
		food_unique_categories = [],
		feed_unique_categories = [];
		
		
		food = products.filter((value)=>{
			return value.type === true;
		})	
		food_categories = food.map((item, key)=>{
			return item.category_label;
		})	
		let unique_food = [...new Set(food_categories)];
		for(let i = 0; unique_food.length > i; i++){
			let id =  [unique_food[i]]
			let item = products.filter((value)=>{
				return value.category_label === unique_food[i];
			})
			let menu = {
				products: item,
				name: id,
			}
			food_unique_categories.push(menu)		
		}
		feed = products.filter((value)=>{
			return value.type === false;
		})
		feed_categories = feed.map((item, key)=>{
			return item.category_label;
		})
		let unique_feed = [...new Set(feed_categories)];
		for(let i = 0; unique_feed.length > i; i++){
			let id =  [unique_feed[i]]
			let item = products.filter((value)=>{
				return value.category_label === unique_feed[i];
			})
			let menu = {
				products: item,
				name: id,
			}
			feed_unique_categories.push(menu)		
		}
		this.setState({
			food,
			feed,
			food_categories: food_unique_categories,
			feed_categories: feed_unique_categories,
		})


	  }
	  fetchPage(){
		let urlId = this.props.match.params.langid;
		this.props.setLanguage(urlId);
		axios.get(`${api}/${urlId}/products.json`).then((res)=>{
		  this.setState({
			products: res.data.products,
			page_content: res.data.products_page,
			labels: res.data.labels,
			loading: false,
		  })
		  this.sortProducts(res.data.products);
		  if(res.data.labels){
			this.props.storeLabels(res.data.labels)
		  }
		  if(res.data.languages){
			this.props.storeLanguages(res.data.languages)
		  }
		 
		  if(res.data.menu){
			this.props.storeMenu(res.data.menu)
		  }
		})
		.catch(error => {
		  if (error.response) {
			/*
			* The request was made and the server responded with a
			* status code that falls out of the range of 2xx
			*/
			console.log(error.response.data);
			console.log(error.response.status);
			console.log(error.response.headers);
			this.props.history.push('/error/404')
		  } else if (error.request) {
			/*
			* The request was made but no response was received, `error.request`
			* is an instance of XMLHttpRequest in the browser and an instance
			* of http.ClientRequest in Node.js
			*/
			this.props.history.push(`/${urlId}/error/unknown-error`)
			console.log(error.request);
		  } else {
			// Something happened in setting up the request and triggered an Error
			console.log('Error', error.message);
			this.props.history.push(`/${urlId}/error/unknown-error`)
		  }
		  console.log(error.config);
		});
	}
	handleChooseFeed(){
		this.setState({
			foodFilter: false,
			sectionChosen: true
		})
	}
	handleChooseFood(){
		this.setState({
			sectionChosen: true
		})
	}
	render() {
		let feed_products = this.state.feed.map((item, key)=>
			<div className="w-full my-4" key={key}>
				<Link to={item.url}>
				<div className="p-2 mb-2">
					<div className="md:flex flex-wrap items-center">
						<div className="md:w-5/12">
							<img src={api + item.image} alt={item.title} className="mb-2 block  shadow-lg"/>
						</div>
						<div className="md:w-1/12"></div>
						<div className="md:w-6/12">
							<h2 className="text-secondary font-bold mt-1 text-3xl">{item.title}</h2>
							<div className="text-primary mt-1 text-sm">{Html(item.introduction)}</div>
							<SubProductList subProducts={item.sub_products}/>
						</div>
					</div>
				</div>
				</Link>
			</div>
		)
		let food_products = this.state.food.map((item, key)=>
			<div className="w-full my-4" key={key}>
			<Link to={item.url}>
				<div className="px-4 mb-2 hover:bg-gray-100">
					
						<div className="md:flex flex-wrap items-center">
						<div className="md:w-5/12">
							
								<img src={api + item.image} alt={item.title} className="mb-2 block shadow-lg"/>
						</div>
						<div className="md:w-1/12"></div>
						<div className="md:w-5/12">
							<h2 className="text-secondary font-bold mt-1 text-3xl">{item.title}</h2>
							<p className="text-primary mt-1 text-md">{Html(item.introduction)}</p>
							<SubProductList subProducts={item.sub_products}/>
						</div>
						<div className="md:w-1/12"></div>
						</div>
					
					
				</div>
				</Link>
		</div>
		)
		let filterFood = this.state.foodFilter === true;
		let productSection = this.state.sectionChosen === true;
		const siteUrl = window.location.href;
		const {googletitle, googledesc, facebookimg} = this.state.page_content;
		const loaded = this.state.loading === false;
		return (
			<>
			{loaded&&
			<div className="wrapper">
				<Helmet>
					<title>{googletitle}</title>
					<meta name="description" content={googledesc} />
					<meta property="og:image:width" content="1000" />
					<meta property="og:image:height" content="730" />
					<meta property="og:url" content={siteUrl} />
					<meta property="og:title" content={googletitle}/>
					<meta property="og:title" content={googletitle}/>
					<meta name="twitter:title" content={googletitle}/>
					<meta property="twitter:title" content={googletitle}/>
					<meta property="og:description" content={googledesc} />
					<meta name="twitter:description" content={googledesc}/>
					<meta property="og:image" content={api + facebookimg} />
					<meta property="og:image:url" content={api + facebookimg}/>
					<meta name="twitter:image" content={api + facebookimg}/>
					<meta name="twitter:card" content={api + facebookimg}/>
				</Helmet>
				{productSection?
				<>	
					
					<div className="bg-primary pt-10 md:pt-56 relative overflow-hidden z-30">
					<div className="absolute w-full left-0 top-0 z-10 notFixed h-full">
						<Video poster='' autoPlay={true}><Video.Source src={HomeVideo} type="video/mp4" /></Video> 
					</div>
					<div className="absolute top-0 z-20 left-0 w-full h-full bg-black bg-opacity-25"></div>
						<div className="pageGrid relative z-20">
							<div className="py-20">
							<h1 className="text-5xl font-bold text-secondary">{this.state.page_content.title}</h1>
							<p className="text-2xl max-w-4xl text-white">{this.state.page_content.introduction}</p>
							</div>
						</div>
					</div>
					<div className="pageGrid min-h-screen">
						<div className="md:flex py-20 flex-wrap w-full">
							<div className="md:w-full">
								<div className="flex mb-10">
									<div className="text-black mr-2">
										<button 
											onClick={this.handleProductFilter.bind(this)}
											className={filterFood? 'bg-secondary py-2 px-6 inline-block rounded-lg shadow-lg':'bg-gray-200 py-2 px-6 inline-block rounded-lg shadow-lg'}>
											{this.state.labels.label_food}
										</button>
									</div>
									<div className="text-black">
										<button onClick={this.handleProductFilter.bind(this)} className={filterFood? 'bg-gray-200 py-2 px-6 inline-block rounded-lg shadow-lg':'bg-secondary py-2 px-6 inline-block rounded-lg shadow-lg'}>
											{this.state.labels.label_feed}
										</button>
									</div>
								</div>
								{filterFood?
									<>
										<h3 className="text-2xl font-bold text-primary px-6">{this.state.labels.label_food}</h3>
										<div className="flex flex-wrap mb-5">{food_products}</div>
									</>
								:
									<>
										<h3 className="text-2xl font-bold text-primary px-6">{this.state.labels.label_feed}</h3>
										<div className="flex flex-wrap">{feed_products}</div>
									</>
								}
							</div>
							
						</div>
					</div>
					<Footer/>
				</>
				:
				<>
					<div className="flex flex-wrap items-center md:items-stretch h-screen w-screen">
						<div className="md:w-6/12 w-full bg-primary flex items-center text-center self-stretch cursor-pointer" onClick={this.handleChooseFood.bind(this)}>
							<div className="w-full"><h2 className="text-secondary text-6xl tracking font-bold m-0 leading-tight">FOOD</h2>
							<div className="text-white uppercase tracking-widest font-semibold text-2xl">Products</div></div>
						</div>
						<div className="md:w-6/12 w-full bg-secondary flex items-center text-center self-stretch cursor-pointer" onClick={this.handleChooseFeed.bind(this)}>
							<div className="w-full"><h2 className="text-primary  text-6xl tracking font-bold m-0 leading-tight">FEED</h2>
							<div className="text-black uppercase tracking-widest font-semibold text-2xl">Products</div></div>
						</div>
					</div>
				</>
				}
			</div>
			}
			</>
		)
	}
}




const MappedAllProducts = connect(
	null,
	mapDispatchToProps
  )(AllProducts);
  
export default MappedAllProducts;




export class SubProductList extends Component {
	render() {
		let list = this.props.subProducts.map((item, key)=>
			<li className="text-primary leading-loose subProductListItem" key={key}> {Html(item.title)} </li>
		)
		return (
			<ul className="my-8 font-bold text-sm">
				{list}
			</ul>
		)
	}
}

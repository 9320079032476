import React, { Component } from 'react'
import axios from 'axios';
import { connect } from "react-redux";
import { storeLanguages, storeMenu, storeLabels, setLanguage } from "../../actions/";
import ContactForm from './ConfactForm';
import Helmet from 'react-helmet';
import Poster from '../../Content/images/Auckland.jpg';
import Footer from '../Footer/Footer'
import Video from 'reactjs-videobg';
import ContactVideo from '../../Content/video/Auckland2.mp4'
import { Parallax } from 'react-scroll-parallax';
const api = process.env.REACT_APP_GRAV_BASE_URL;

function mapDispatchToProps(dispatch) {
	return {
        storeLanguages: languages => dispatch(storeLanguages(languages)),
        storeMenu: menu => dispatch(storeMenu(menu)),
        storeLabels: labels => dispatch(storeLabels(labels)),
        setLanguage: language => dispatch(setLanguage(language)),
	};
}
export class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            labels: {},
            content: {},
            image: '',
            windowWidth: '',
            windowHeight: '',
            loading: true,
        }
    }
    checkDimensions(){
        this.setState({windowWidth: window.innerWidth, windowHeight: window.innerHeight})
    }
    componentDidUpdate(nextProps){
        if(this.props !== nextProps){
          this.fetchPage();
        }
      }
      componentDidMount() {
        this.fetchPage();
        this.checkDimensions();
        window.addEventListener('resize', this.checkDimensions.bind(this))
    
      }
      fetchPage(){
        let urlId = this.props.match.params.langid;
        this.props.setLanguage(urlId)
        axios.get(`${api}/${urlId}/contact.json`).then((res)=>{
          this.setState({
            content: res.data.content,
            labels: res.data.labels,
            menu: res.data.menu,
            languages: res.data.language,
            image: res.data.image,
            loading: false,
          })
          if(res.data.labels){
            this.props.storeLabels(res.data.labels)
          }
          if(res.data.languages){
            this.props.storeLanguages(res.data.languages)
          }
          
          if(res.data.menu){
            this.props.storeMenu(res.data.menu)
          }
        })
        .catch(error => {
          if (error.response) {
            /*
            * The request was made and the server responded with a
            * status code that falls out of the range of 2xx
            */
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            this.props.history.push('/error/404')
          } else if (error.request) {
            /*
            * The request was made but no response was received, `error.request`
            * is an instance of XMLHttpRequest in the browser and an instance
            * of http.ClientRequest in Node.js
            */
            this.props.history.push(`/${urlId}/error/unknown-error`)
            console.log(error.request);
          } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
            this.props.history.push(`/${urlId}/error/unknown-error`)
          }
          console.log(error.config);
        });
      }
    
    render() {
      const siteUrl = window.location.href;
      const {title, googletitle, googledesc, facebookimg} = this.state.content;
      const loaded = this.state.loading === false;
      console.log(this.state)
        return (
          <>
          {loaded&&
            <div className="bg-white">
            <Helmet>
              <title>{`${title} | Laclat`}</title>
	            <meta name="description" content={googledesc} />
	            <meta property="og:image:width" content="1000" />
	            <meta property="og:image:height" content="730" />
	           	<meta property="og:url" content={siteUrl} />
              <meta property="og:title" content={googletitle}/>
              <meta property="og:title" content={googletitle}/>
              <meta name="twitter:title" content={googletitle}/>
              <meta property="twitter:title" content={googletitle}/>
	            <meta property="og:description" content={googledesc} />
	            <meta name="twitter:description" content={googledesc}/>
	            <meta property="og:image" content={api + facebookimg} />
	        	  <meta property="og:image:url" content={api + facebookimg}/>
	        	  <meta name="twitter:image" content={api + facebookimg}/>
              <meta name="twitter:card" content={api + facebookimg}/>
            </Helmet>
            <div className="relative notFixed overflow-hidden">
            
            
            <div className="absolute bottom-0 right-0 p-5 text-black text-white z-40 font-bold md:block display-none">Auckland, New Zealand</div>
              <div className="self-stretch flex w-full h-full">
                <div  className="absolute top-0 left-0 w-full z-10 h-full">
                  <Video poster={Poster} autoPlay={true}><Video.Source src={ContactVideo} type="video/mp4" /></Video>
                </div>
              </div>
            
            <div className="py-10 md:py-48 lg:py-56 z-20 relative self-stretch">
                <div className="md:max-4xl mx-auto text-center md:py-10 md:text-white">
                <Parallax y={[5, -25]} tagOuter='span'>
                    <h1 className="text-4xl lg:text-6xl font-bold md:py-10 md:my-12">{this.state.content.title}</h1>
                </Parallax>
                </div>
            </div>
            
            </div>
           
            <div className="z-20 relative bg-white">
                <div className="mx-auto bg-white md:px-20 md:py-10">
                    <div className="pageGrid">
                        <div className="flex items-center flex-wrap">
                          
                          <div className="md:w-4/12 text-left text-primary font-bold">
                            <p className="text-secondary md:text-2xl leading-tight text-2xl font-bold md:mt-56 md:mb-48">{this.state.content.introduction}</p>
                            <div className="self-stretch pt-10">
                              <p className="md:mt-4">{this.state.content.contact_email}</p>
                              <p>{this.state.content.contact_postal}</p>
                            </div>
                          </div>
                          <div className="w-1/12"></div>
                          <div className="md:w-md-5">
                            <ContactForm labels={this.state.labels}/>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
          
            
            <Footer short={true}/>
            </div>
          }
          </>
        )
    }
}

const ConnectedContact = connect(
	null,
	mapDispatchToProps
  )(Contact);
  
export default ConnectedContact;

import {
    STORE_LABELS,
    SET_LANGUAGE,
    STORE_MENU,
    STORE_LANGUAGES
} from "../constants/action-types";

const initialState = {
    labels: {},
    language: 'en',
    menu: [],
    languages: [],

};

function rootReducer(state = initialState, action) {
    if (action.type === STORE_LABELS) {
        return Object.assign({}, state, {
            labels: action.payload
        });
    }
    if (action.type === SET_LANGUAGE) {
        return Object.assign({}, state, {
            language: action.payload
        });
    }
    if (action.type === STORE_MENU) {
        return Object.assign({}, state, {
            menu: action.payload
        });
    }
    if (action.type === STORE_LANGUAGES) {
        return Object.assign({}, state, {
            languages: action.payload
        });
    }
    return state;
}

export default rootReducer;
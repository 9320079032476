import React, {Component} from 'react';
import {Router,Route,Switch} from 'react-router-dom';
import { connect } from "react-redux";
import { storeLabels} from "./actions/index";
import history from './history.js';
import Analytics from 'react-router-ga';
import CookieConsent from "react-cookie-consent";
//
//
//  stlesheets 
import './Content/css/tailwind/tailwind.css';
import './Content/css/bootstrap.css';
import './Content/css/master.css';
import './Content/fonts/icons/flaticon.css';
// PAGE TEMPLATES
import AllProductsPage from './Components/AllProductsPage/AllProductsPage';
import SingleProductPage from './Components/SingleProduct/SingleProductPage';
import ErrorPage from './Components/Errors/ErrorPage';
import PageNotFound from './Components/Errors/PageNotFound';
import Contact from './Components/Contact/Contact';
import About from './Components/AboutPage/AboutPage';
import HomePage from './Components/HomePage/HomePage'
// APP COMPONENTS
import Header from './Components/Header/Header';
import Services from './Components/Services/Services'
import LandingDirect from './Components/HomePage/LandingRedirect';
import CookieDisclaimer from 'react-cookie-disclaimer';
import News from './Components/News/News';
import Article from './Components/News/Article';
import WildCardNotFound from './Components/Errors/WildCard404';
import PrivacyPolicy from './Components/Policies/Privacy';
import TermsConditions from './Components/Policies/Conditions'
import { ParallaxProvider } from 'react-scroll-parallax';
import Ventures from './Components/Ventures/Ventures';
function mapDispatchToProps(dispatch) {
	return {
    storeLabels: labels => dispatch(storeLabels(labels)),
	};
  }
class ConnectedApp extends Component {
  constructor(props) {
    super(props);
  
    this.state = {
      language: '/zh',
      products: [],
      news: [],
      home: {},
      productsPage: {},
      services: {
        services: [],
      },
      labels: {},
      contact: {},
      languages: [],
      menu: [],

    };
  }
  render() {
    let products = this.state.products.map((item,key)=>
      <Route exact 
        path={item.url} 
        key={key}
        component={(props) => 
          <SingleProductPage {...props}  content={item}/>
      } />
    )
    
    return (
      <>
      <>
      <ParallaxProvider>  
        <Router history={history} basename={'/en'}>
              <>
              <Header menu={this.state.menu} languages={this.state.languages} labels={this.state.labels}/>
              <CookieDisclaimer 
                background='#004f70' 
                bottomPosition={false}
                closeIconSize={30}
                closeIconPositionTop={false}
                color='#fff'
                cookiePolicyName='Terms and Conditions'
                cookiePolicyLink='/en/terms-and-conditions' 
                cookiePolicyText='By continuing to use the service, you agree to our'
                text='This website uses cookies to improve service.'
              />
              <Analytics id="UA-170811549-1">
                <Switch>
                    <Route exact path="/" component={(props)=><LandingDirect  {...props} />}/>
                    <Route exact path="/:langid" component={(props)=><HomePage content={this.state.home} products={this.state.productsPage} services={this.state.services} {...props} />}/>
                    <Route exact  path='/:langid/error/unknown-error' component={(props)=><ErrorPage {...props} />}/>
                     <Route exact path='/:langid/products/:product'component={(props)=><SingleProductPage {...props} />}/>
                    {products}
                    <Route exact path='/:langid/error/404' component={(props)=><PageNotFound {...props} />}/>
                    <Route exact 
                      path='/:langid/products' 
                      component={(props) => <AllProductsPage {...props}  content={this.state.gravContent} />} 
                    />
                    <Route exact 
                      path='/:langid/services' 
                      component={(props) => <Services {...props} labels={this.state.labels} content={this.state.services} history={history}/>} 
                    />
                    <Route exact 
                      path='/:langid/services/acquisitions' 
                      component={(props) => <Ventures {...props} labels={this.state.labels} history={history}/>} 
                    />
                    <Route exact 
                      path='/:langid/news' 
                      component={(props) => <News {...props} labels={this.state.labels} history={history}/>} 
                    />
                    <Route exact 
                      path='/:langid/news/:article' 
                      component={(props) => <Article {...props} labels={this.state.labels} history={history}/>} 
                    />
                    <Route exact 
                      path='/:langid/about-us' 
                      component={(props) => <About {...props} labels={this.state.labels} history={history}/>} 
                    />
                    <Route exact path="/:langid/terms-and-conditions" component={(props) => <TermsConditions {...props} labels={this.state.labels} history={history}/>} />
                    <Route exact path="/:langid/privacy-policy" component={(props) => <PrivacyPolicy {...props} labels={this.state.labels} history={history}/>} />
                    <Route exact 
                      path='/:langid/contact' 
                      component={(props) => <Contact {...props} labels={this.state.labels} content={this.state.contact} history={history}/>} 
                    />
                    
                    <Route exact path='/*'component={(props)=><WildCardNotFound history={history} PageNotFound {...props} />}/>
                    <Route exact path='/404.html'component={(props)=><WildCardNotFound history={history} PageNotFound {...props} />}/>
                   <CookieConsent
                      acceptOnScroll={true}
                      acceptOnScrollPercentage={50}
                      onAccept={() => {alert("consent given")}}
                      
                  >
                    By using this site you agree to the use of cookies for analytics, personalized content and ads
                  </CookieConsent>
                  
                </Switch>
                </Analytics>  
              </>
        </Router>
        </ParallaxProvider>
      </>
      </>
    )
  }
  
}
const App = connect(
	null,
	mapDispatchToProps
  )(ConnectedApp);
  
export default App;
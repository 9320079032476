const firebase = require('firebase/app');
require('firebase/database');
const APIKEY = process.env.REACT_APP_FIREBASE_APIKEY
const AUTHDOMAIN = process.env.REACT_APP_FIREBASE_AUTHDOMAIN
const DATABASEURL = process.env.REACT_APP_FIREBASE_DATABASEURL
const PROJECTID = process.env.REACT_APP_FIREBASE_PROJECTID
const STORAGEBUCKET = process.env.REACT_APP_FIREBASE_STORAGEBUCKET
const MESSAGINGSENDERID = process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID
const APPID = process.env.REACT_APP_FIREBASE_APPID
const firebaseConfig = {
    apiKey: APIKEY,
    authDomain: AUTHDOMAIN,
    databaseURL: DATABASEURL,
    projectId: PROJECTID,
    storageBucket: STORAGEBUCKET,
    messagingSenderId: MESSAGINGSENDERID,
    appId: APPID
  };

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}
const db = firebase.database();
export {
  db,
};
import React, { Component } from 'react'

export default class WildCard404 extends Component {
    componentDidMount() {
        this.props.history.push('/en/error/404');
    }
    
    render() {
        return (
            <div></div>
        )
    }
}

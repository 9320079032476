import React, { Component } from 'react'
import validator from 'email-validator';
import {db} from '../../firebase'
import ConfirmationGif from '../../Content/images/tick.gif'
import Bg from '../../Content/images/callback.jpg'
import {countries} from 'countries-list'
export default class CallBackForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            company: '',
            phone: '',
            subject: '',
            validate: false,
            success: false,
            height: '',
            countryList: [],
            country: '',
            
        }
    }
    changeField({target}){
        let field = target.getAttribute('data-field');
        if(field){
            this.setState({[field]: target.value})
        }
        
    }
    componentDidMount() {
        const formWrapper = document.getElementById('formWrapper');
        this.setState({height: formWrapper.pageOffset});
        const countryList = [{name: ''}]
        let map = Object.keys(countries).map((item)=>{
            countryList.push({name: countries[item].name});
            return null;
        })
        this.setState({countryList, map})
    }
    changeCountry({target}){
        this.setState({country: target.value})
    }
    submitForm(){
        const {name, phone, email, company, subject, country} = this.state;
        let valid = name.length > 0 && phone.length > 3 && company.length > 2 && country.length > 0 && subject.length > 0 && validator.validate(email) === true;
        if(valid){
            let msg = {
                phone, email, company, subject, name, country
            }
            db.storeEmail(msg).then(()=>{
                this.setState({sucess: true})
            })
            .catch((err)=>console.log(err))
        }
        else {
            this.setState({validate: true})
            this.setState({success: false})
        }
    }
    render() {
        let countryOptions = this.state.countryList.map((item, key)=>
            <option key={key}>{item.name}</option>
        )
        const nameInvalid = this.state.name.length === 0 && this.state.validate === true
        const emailInvalid = validator.validate(this.state.email) === false && this.state.validate === true
        const phoneInvalid = this.state.phone.length === 0 && this.state.validate === true
        const subjectInvalid = this.state.subject.length === 0 && this.state.validate === true
        const companyInvalid = this.state.company.length === 0 && this.state.validate === true;
        const countryInvalid = this.state.country.length === 0 && this.state.validate === true
        const success = this.state.success === true;
        const wrapperStyle = {
            height: this.state.height,
        }
        let bgStyle = {
            backgroundImage: `url(${Bg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
        }
        return (
            <div className="bg-primary py-20" style={bgStyle}>
                <div className="pageGrid">
                    <div className="md:flex items-center">
                        <div className="md:w-5/12 lg:w-7/12">
                            <h2 className="text-secondary text-3xl md:text-5xl leading-tight font-bold md:mb-0 mb-6">
                                {this.props.labels.label_callback_blurb}
                            </h2>
                        </div>
                        <div className="md:w-1/12"></div>
                        <div className="md:w-6/12 lg:w-5/12" id="formWrapper">
                        {success?
                            <div className="bg-white p-10">
                            {wrapperStyle.height}
                            <img src={ConfirmationGif} alt="" className="w-full mx-auto" width="300" height="300"/>
                            <p className="font-bold text-green-500 text-center text-2xl my-4">{this.props.labels.label_message_sent}</p>
                            </div>
                            :
                            <div className="bg-white p-8 rounded-lg shadow-lg">
                                <div className="my-2">
                                    <label className="capitalize text-primary font-bold">{this.props.labels.label_name}
                                        <input 
                                            type="text" 
                                            onChange={this.changeField.bind(this)} 
                                            data-field="name"
                                            value={this.state.name}
                                            className={nameInvalid?"formControl focus:outline outline-none focus:shadow-outline w-full is-invalid":"formControl focus:outline outline-none focus:shadow-outline w-full"}
                                        />
                                    </label>
                                </div>
                                <div className="my-2">
                                    <label className="capitalize text-primary font-bold">{this.props.labels.label_email}
                                        <input 
                                            type="text" 
                                            onChange={this.changeField.bind(this)} 
                                            data-field="email"
                                            value={this.state.email}
                                            className={emailInvalid?"formControl focus:outline outline-none focus:shadow-outline w-full is-invalid":"formControl focus:outline outline-none focus:shadow-outline w-full"}
                                        />
                                    </label>
                                </div>
                                <div className="my-2">
                                    <label className="capitalize text-primary font-bold">{this.props.labels.label_company}
                                        <input 
                                            type="text" 
                                            onChange={this.changeField.bind(this)} 
                                            data-field="company"
                                            value={this.state.company}
                                            className={companyInvalid?"formControl focus:outline outline-none focus:shadow-outline w-full is-invalid":"formControl focus:outline outline-none focus:shadow-outline w-full"}
                                        />
                                    </label>
                                </div>
                                <div className="my-2">
                                    <label className="capitalize text-primary font-bold">{`${this.props.labels.label_phone} (${this.props.labels.label_ideally_mobile})`}
                                        <input 
                                            type="text" 
                                            onChange={this.changeField.bind(this)} 
                                            data-field="phone"
                                            value={this.state.phone}
                                            className={phoneInvalid?"formControl focus:outline outline-none focus:shadow-outline w-full is-invalid":"formControl focus:outline outline-none focus:shadow-outline w-full"}
                                        />
                                    </label>
                                </div>
                                <div className="my-2">
                                    <label className="capitalize text-primary font-bold">{`${this.props.labels.label_country}`}
                                        <select className={countryInvalid?"formControl outline-none is-invalid pr-10":"formControl outline-none"} value={this.state.country} onChange={this.changeCountry.bind(this)}>
                                            {countryOptions}
                                        </select>
                                    </label>
                                </div>
                                <div className="my-2">
                                    <label className="capitalize text-primary font-bold">{this.props.labels.label_subject}
                                        <input 
                                            type="text" 
                                            onChange={this.changeField.bind(this)} 
                                            data-field="subject"
                                            value={this.state.subject}
                                            className={subjectInvalid?"formControl focus:outline outline-none focus:shadow-outline w-full is-invalid":"formControl focus:outline outline-none focus:shadow-outline w-full"}
                                        />
                                    </label>
                                </div>
                                <div className="my-2">
                                    <button onClick={this.submitForm.bind(this)} className="rounded-lg w-full py-3 px-4 bg-secondary inline-block my-2 capitalize text-primary">
                                        {this.props.labels.label_send}
                                    </button>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

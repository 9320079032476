import React, { Component } from 'react'
import axios from 'axios';
import { connect } from "react-redux";
import { storeLanguages, storeMenu, storeLabels, setLanguage } from "../../actions/";
import Html from 'react-html-parser';
import {Link} from 'react-router-dom'
import Footer from '../Footer/Footer';
import Helmet from 'react-helmet';
import { Parallax } from 'react-scroll-parallax';
const api = process.env.REACT_APP_GRAV_BASE_URL;
function mapDispatchToProps(dispatch) {
	return {
        storeLanguages: languages => dispatch(storeLanguages(languages)),
        storeMenu: menu => dispatch(storeMenu(menu)),
        storeLabels: labels => dispatch(storeLabels(labels)),
        setLanguage: language => dispatch(setLanguage(language)),
	};
}
export class News extends Component {
  constructor(props) {
    super(props);
    this.state = {
      articles: [],
      content: {},
      labels: {},

    }
  }
  componentDidUpdate(nextProps){
      if(this.props !== nextProps){
        this.fetchPage();
      }
    }
    componentDidMount() {
      window.scrollTo(0,0)
      this.fetchPage();
    }
    fetchPage(){
      let urlId = this.props.match.params.langid;
      this.props.setLanguage(urlId)
      axios.get(`${api}/${urlId}/news.json`).then((res)=>{
        this.setState({
          articles: res.data.articles,
          content: res.data.header,
          image: res.data.image,
          labels: res.data.labels,
        })
        if(res.data.labels){
          this.props.storeLabels(res.data.labels)
        }
        if(res.data.languages){
          this.props.storeLanguages(res.data.languages)
        }
        
        if(res.data.menu){
          this.props.storeMenu(res.data.menu)
        }
      })
      .catch(error => {
        if (error.response) {
          /*
          * The request was made and the server responded with a
          * status code that falls out of the range of 2xx
          */
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          this.props.history.push('/error/404')
        } else if (error.request) {
          /*
          * The request was made but no response was received, `error.request`
          * is an instance of XMLHttpRequest in the browser and an instance
          * of http.ClientRequest in Node.js
          */
          this.props.history.push(`/${urlId}/error/unknown-error`)
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log('Error', error.message);
          this.props.history.push(`/${urlId}/error/unknown-error`)
        }
        console.log(error.config);
      });
    }
    render() {
      const siteUrl = window.location.href;
    	const {title, googletitle, googledesc, facebookimg} = this.state.content;
      let articles = this.state.articles.map((item, key)=>
        <div className="md:w-4/12 self-stretch p-2" key={key}>
          <div className="p-2 self-stretch h-full border border-gray-200 rounded-lg bg-white flex flex-wrap shadow-md">
            <div className="self-stretch p-4">
              <span className="mb-2 block font-semibold text-secondary">{item.date}</span>
              <h2 className="font-bold text-3xl text-primary leading-tight">{item.title}</h2>
              {Html(item.summary)}
            </div>
            <Link to={item.url} className="text-blue-500 my-2 w-full px-4 mb-4 inline-block">{this.state.labels.label_read_article}</Link>
          </div>
         
        </div>
      )
      const headerBg = {
        background: `url(${api}/${this.state.image})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
      }
        return (
            <>
            
              <Helmet>
                <title>{`${title} | Laclat`}</title>
                <meta name="description" content={googledesc} />
                <meta property="og:image:width" content="1000" />
                <meta property="og:image:height" content="730" />
                <meta property="og:url" content={siteUrl} />
                <meta property="og:title" content={googletitle}/>
                <meta property="og:title" content={googletitle}/>
                <meta name="twitter:title" content={googletitle}/>
                <meta property="twitter:title" content={googletitle}/>
                <meta property="og:description" content={googledesc} />
                <meta name="twitter:description" content={googledesc}/>
                <meta property="og:image" content={api + facebookimg} />
                <meta property="og:image:url" content={api + facebookimg}/>
                <meta name="twitter:image" content={api + facebookimg}/>
                <meta name="twitter:card" content={api + facebookimg}/>
              </Helmet>
              
            
            <div className="bg-primary z-20 relative overflow-hidden">
            <div className="top-0 left-0 h-full bottom-0  md:w-full z-10" style={headerBg}>
              <Parallax  y={[10, -10]} tagOuter="figure">

               
              
                <div className="md:pt-56 pt-20">
                <div className="pageGrid px-2 relative z-20">
                  <h1 className="md:text-6xl text-4xl text-center font-bold text-white py-20">{this.state.content.title}</h1>
                </div>
                </div>
              </Parallax>
              
            </div> 
            </div> 
            <div>
                <div className="py-20">
                    <div className="pageGrid">
                        <div className="md:flex flex-wrap items-stretch">
                          {articles}
                        </div>
                    </div>
                </div>
            </div>
            
            <Footer/>
            </>
        )
    }
}
const ConnectedNews = connect(
	null,
	mapDispatchToProps
  )(News);
  
export default ConnectedNews;

import { db } from './firebase';

// User API

export const storeEmail = (email) =>
  db.ref(`logs/callbacks/`).push({
    email
  });
  export const storeEmailWithMessage = (email) =>
  db.ref(`logs/emails/`).push({
    email
  });

import React, { Component } from 'react'

export default class LandingRedirect extends Component {
    componentDidMount() {
        this.props.history.push('/en')
    }
    
    render() {
        return (
            <div>
                
            </div>
        )
    }
}

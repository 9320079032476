import React, { Component } from 'react'
import {Link} from 'react-router-dom';
import Logo from '../../Content/images/webLogo2.svg';
import MobileLogo from '../../Content/images/webLogo.svg';
import { connect } from "react-redux";
const mapStateToProps = (state, ownProps) => {
	return { 
		languages: state.languages, 
		menu: state.menu, 
		language: state.language, 
		labels: state.labels, 
	};
  };
const HeaderConnected = ({ labels,menu,language,languages }) => (
	<Header labels={labels} menu={menu} language={language} languages={languages}/>
);  
export class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			width: 0,
			height: 0,	
		}
	}
	checkDimensions(){
		this.setState({
			width: window.innerWidth,
			height: window.innerHeight,
		})
	}
	componentDidMount() {
		this.checkDimensions();
		window.addEventListener('resize', this.checkDimensions.bind(this))
	}
    render() {
		let desktop = this.state.width > 900;
        return (
            <div className="z-50 px-1 py-2 md:absolute w-full relative">
                <div className="pageGrid">
                    <div className="flex py-2 flex-start w-full">
						<Link to='/'>
							{desktop?
								<img src={Logo} alt="Laclat" className="w-64 mt-2 relative z-40 sm-hide md-show"/>
								:
								<img src={MobileLogo} alt="Laclat" className="w-32 mt-2 relative z-40 md-hide"/>
							}
						</Link>
                        <div className="mx-auto">
							{desktop?
                            	<Navigation menu={this.props.menu} language={this.props.language} languages={this.props.languages} labels={this.props.labels}/>
								:
								<MobileNavigation menu={this.props.menu} language={this.props.language} languages={this.props.languages} labels={this.props.labels}/>
							}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export class Navigation extends Component {
    render() {
        let menu = this.props.menu.map((item, key)=>
            <MenuItem key={key} item={item}/>
        )
        return (
            <ul className="flex items-center py-4">
                {menu}
				<LanguageSwitcher language={this.props.language} languages={this.props.languages}/>
            </ul>
        )
    }
}
class MenuItem extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
		}
		this.timer = null;
	}
	
	hoverDropDown(){
		clearTimeout(this.timer);
		this.setState({ open: true })
		
	}
	leaveDropdown(){
		this.timer = setTimeout(()=>{this.setState({open: false })},800)
	}
	render() {
		const {dropdown} = this.props.item;
		return (
			<>
				{dropdown?
				<li className="p-1 mx-4 font-semibold text-lg text-white whitespace-no-wrap" onMouseEnter={this.hoverDropDown.bind(this)} onMouseLeave={this.leaveDropdown.bind(this)}>
					<Link to={this.props.item.link}>{this.props.item.title} <span className="flaticon-download-2 text-xs"></span></Link>
					<DropdownNavigation dropdown={this.props.item.dropdown} open={this.state.open}/>
				</li>
				:
				<li className="p-1 mx-4 font-semibold text-lg text-white whitespace-no-wrap"><Link to={this.props.item.link}>{this.props.item.title}</Link></li>
				}
			</>
		);
	}
}

class DropdownNavigation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			food: [],
			feed: [],
			foodLabel: '',
			feedLabel: '',
		}
	}
	componentDidMount() {
		this.sortMenu();
	}
	componentDidUpdate(prevProps) {
		if(prevProps !== this.props){
			this.sortMenu()
		}
	}
	sortMenu(){
		const menu = this.props.dropdown;
		const food = menu.filter((value)=>{
			if(value.type === true) {
				return value
			}
			return null
		})
		const feed = menu.filter((value)=>{
			if(value.type === false) {
				return value
			}
			else {
				return null
			}
		})
		this.setState({
			food, feed
		})
		if(food.length > 0){
			this.setState({foodLabel: food[0].type_label})
		}
		if(feed.length > 0){
			this.setState({feedLabel: feed[0].type_label})
		}
	}
	
	render() {
		let foodMenu = this.state.food.map((item, key)=>
			<li key={key} className="py-1 dropdown-item text-sm font-bold px-2 whitespace-normal"><Link to={item.link}>{item.title}</Link></li>
		)
		let feedMenu = this.state.feed.map((item, key)=>
			<li key={key} className="py-1 dropdown-item text-sm font-bold px-2 whitespace-normal"><Link to={item.link}>{item.title}</Link></li>
		)
		let {open} = this.props;
		return (
			<>
			{open&&
			<div className="text-black absolute mt-2">
				<div className="w-12/12 text-primary mx-4 shadow-lg rounded-lg grid">
					<div className="flex items-start relative dropdownSubTrigger self-stretch grid">
						<div className="capitalize bg-white pl-4 pr-2 py-2 subLabel text-sm font-normal w-full">
							{this.state.foodLabel} <span className="flaticon-next float-right ml-2 text-right inline-block text-sm"></span>
						</div>
						<div className="dropdownSub bg-white">
							{foodMenu}
						</div>
					</div>
					<div className="flex items-start relative dropdownSubTrigger self-stretch grid">
						<div className="capitalize bg-white pl-4 pr-2 py-2 subLabel text-sm font-normal">
							{this.state.feedLabel} <span className="flaticon-next float-right ml-2 text-right inline-block text-sm"></span>
						</div>
						<div className="dropdownSub bg-white">
							{feedMenu}
						</div>
					</div>
				</div>
			</div>
			}
			</>
		);
	}
}



export class MobileNavigation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
		}
	}
	toggleMobileMenu(){
		const status = this.state.open;
		this.setState({open: !status})
	}
	handleLink(){
		this.setState({open: false});
	}
	render() {
		let menu = this.props.menu.map((item, key)=>
			<li key={key} className="my-4"><Link className="py-2 text-2xl font-light pl-4" onClick={this.handleLink.bind(this)} to={item.link}>{item.title}</Link></li>
		)
		let menuOpen = this.state.open === true; 
		return (
			<>
				<div className="w-10 absolute right-0 mr-5 cursor-pointer z-50 mobileMenuBt" data-active={menuOpen} onClick={this.toggleMobileMenu.bind(this)}>
					<span className="bg-secondary block h-1 w-full my-2"></span>
					<span className="bg-secondary block h-1 w-full my-2"></span>
					<span className="bg-secondary block h-1 w-full my-2"></span>
				</div>
				<ul className="absolute top-0 p-2 mt-2" style={{right: '70px'}}>
					<LanguageSwitcher language={this.props.language} languages={this.props.languages}/>
				</ul>
				{menuOpen&&
					<div className="bg-white absolute left-0 top-0 w-full py-4 px-4 pt-32 z-30 text-primary shadow-lg">
						<ul className="mb-4">{menu}</ul>
					</div>
				}
				<div className="bg-black absolute left-0 top-0 w-full py-4 px-4 pt-24 z-30 text-white shadow-lg" style={{display: 'none'}}>
						<ul className="mb-4">{menu}</ul>
				</div>
			</>
		)
	}
}


export class LanguageSwitcher extends Component {
    constructor(props){
        super(props);
        this.state = {
            dropDownOpen: false,
            activeLanguage: '',
		};
		this.timer = null;
		
    }
    handleDropDown(){
		clearTimeout(this.timer);
		this.setState({dropDownOpen: true,})
	}
	handleDropDownOff(){
		this.timer = setTimeout(()=>{this.setState({dropDownOpen: false })},800)
	}
	handleClickDropDown(){
		this.setState({ dropDownOpen: true })
	}
    componentDidMount(){
        let languages = this.props.languages;
        for(let i = 0; languages.length > i; i++){
            if(languages[i].active){
                this.setState({activeLanguage: languages[i].name})
            }
        }
    }
    render() {
        let dropDownOpen = this.state.dropDownOpen === true;
        let languageList = this.props.languages.map((item, key)=>
            <li key={key} className="languageSub px-4 py-2">
                <Link to={ item.lang + item.page} className="flex"> {item.name}</Link>
            </li>
		)
		let hasLanguages = this.props.languages.length > 0;
        return (
			<>
				{hasLanguages&&
				<li onMouseEnter={this.handleDropDown.bind(this)} onMouseLeave={this.handleDropDownOff.bind(this)} onClick={this.handleClickDropDown.bind(this)} className="h-10 py-2 px-2 rounded-lg bg-white">
					<div className="flex items-center text-primary">
						<span className="uppercase font-bold mr-2 text-md pl-2">{this.props.language}</span><span className="flaticon-download-2 text-xs"></span>
					</div>
					{dropDownOpen &&
						<div className={"dropDownMenuWrapper bg-white absolute mt-2 -ml-2 rounded-lg shadow-lg lang overflow-hidden " + this.state.dropDownOpen}>
							<ul className="text-primary">{languageList}</ul>
						</div>
					}
				</li>
				}
			</>
        )
    }
}


const ReduxHeader = connect(mapStateToProps)(HeaderConnected);

export default ReduxHeader;

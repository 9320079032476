import React, { Component } from 'react'
import axios from 'axios';
import { connect } from "react-redux";
import { storeLanguages, storeMenu, storeLabels, setLanguage } from "../../actions/";
import Helmet from 'react-helmet';
import Footer from '../Footer/Footer'
import { Parallax } from 'react-scroll-parallax';
import VenturesBanner from '../../Content/images/ventures.jpg'
import {Link} from 'react-router-dom';
import Html from 'react-html-parser';
const api = process.env.REACT_APP_GRAV_BASE_URL;

function mapDispatchToProps(dispatch) {
	return {
        storeLanguages: languages => dispatch(storeLanguages(languages)),
        storeMenu: menu => dispatch(storeMenu(menu)),
        storeLabels: labels => dispatch(storeLabels(labels)),
        setLanguage: language => dispatch(setLanguage(language)),
	};
}
export class Ventures extends Component {
    constructor(props) {
        super(props);
        this.state = {
            labels: {},
            content: {},
            image: '',
            windowWidth: '',
            windowHeight: '',
            companies: [],
        }
    }
    checkDimensions(){
        this.setState({windowWidth: window.innerWidth, windowHeight: window.innerHeight})
    }
    componentDidUpdate(nextProps){
        if(this.props !== nextProps){
          this.fetchPage();
        }
      }
      componentDidMount() {
        this.fetchPage();
        this.checkDimensions();
        window.addEventListener('resize', this.checkDimensions.bind(this))
    
      }
      fetchPage(){
        let urlId = this.props.match.params.langid;
        this.props.setLanguage(urlId)
        axios.get(`${api}/${urlId}/services/acquisitions.json`).then((res)=>{
          this.setState({
            content: res.data.content,
            labels: res.data.labels,
            menu: res.data.menu,
            languages: res.data.language,
            image: res.data.image,
          })
          if(res.data.content.companies){
            this.setState({companies: res.data.content.companies})
          }
          if(res.data.labels){
            this.props.storeLabels(res.data.labels)
          }
          if(res.data.languages){
            this.props.storeLanguages(res.data.languages)
          }
          
          if(res.data.menu){
            this.props.storeMenu(res.data.menu)
          }
        })
        .catch(error => {
          if (error.response) {
            /*
            * The request was made and the server responded with a
            * status code that falls out of the range of 2xx
            */
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            this.props.history.push('/error/404')
          } else if (error.request) {
            /*
            * The request was made but no response was received, `error.request`
            * is an instance of XMLHttpRequest in the browser and an instance
            * of http.ClientRequest in Node.js
            */
            this.props.history.push(`/${urlId}/error/unknown-error`)
            console.log(error.request);
          } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
            this.props.history.push(`/${urlId}/error/unknown-error`)
          }
          console.log(error.config);
        });
      }
    
    render() {
      const siteUrl = window.location.href;
      const companies = this.state.companies.map((item, key)=>
        <div key={key}>
          <h3 className="m-0 text-3xl font-bold text-secondary leading-tight">{item.title}</h3>
          <p>{item.description}</p>
          <p className="font-bold text-primary">{item.location}</p>
        </div>
      )
      const hasCompanies = this.state.companies.length > 0;
      const {title, googletitle, googledesc, facebookimg} = this.state.content;
      const bg = {
        background: `url(${VenturesBanner})`,
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
      }
        return (
            <div className="bg-gray-200">
            <Helmet>
              <title>{title}</title>
	            <meta name="description" content={googledesc} />
	            <meta property="og:image:width" content="1000" />
	            <meta property="og:image:height" content="730" />
	           	<meta property="og:url" content={siteUrl} />
              <meta property="og:title" content={googletitle}/>
              <meta property="og:title" content={googletitle}/>
              <meta name="twitter:title" content={googletitle}/>
              <meta property="twitter:title" content={googletitle}/>
	            <meta property="og:description" content={googledesc} />
	            <meta name="twitter:description" content={googledesc}/>
	            <meta property="og:image" content={api + facebookimg} />
	        	  <meta property="og:image:url" content={api + facebookimg}/>
	        	  <meta name="twitter:image" content={api + facebookimg}/>
              <meta name="twitter:card" content={api + facebookimg}/>
            </Helmet>
            <div className="bg-primary z-20 relative overflow-hidden" style={bg}>
              <Parallax  y={[10, -50]} tagOuter="figure">
                
                <div className="pt-56">
                <div className="pageGrid px-2 relative z-20">
                  <h1 className="text-3xl md:text-4xl lg:text-6xl text-center font-bold text-white py-20">{Html(this.state.content.heading)}</h1>
                </div>
                </div>
              </Parallax>
            </div>
            <div className="py-20">
              <div className="pageGrid">
                {hasCompanies? <>{companies}</>: <div className="text-primary text-center"><h2 className="text-2xl md:text-4xl text-secondary font-bold">{this.state.labels.company_list}</h2><p className="text-2xl"><Link to="/en/contact">{this.state.labels.contact_us}</Link> {this.state.labels.further_info}</p></div>}
              </div>
            </div>
            <Footer/>
            </div>
        )
    }
}

const VenturesConnected = connect(
	null,
	mapDispatchToProps
  )(Ventures);
  
export default VenturesConnected;

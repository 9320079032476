
import React, { Component } from 'react'
import Video from 'reactjs-videobg';
import HomeVideo from '../../Content/video/home4_1.mp4';
import HomeVideoMobile from '../../Content/video/mobile.mp4';
import Poster from '../../Content/images/cow.jpg';
import MobilePoster from '../../Content/images/mobile_still.jpg';
const makeMuted = (elt) => {
    if (elt) {
      elt.muted = true;
    }
  };
export default class HeaderBanner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            windowHeight: window.innerHeight,
            windowWidth: window.innerWidth,
        }
    }
    
    componentDidMount() {
        this.checkDimensions();
        window.addEventListener('resize', this.checkDimensions.bind(this))
    }
    checkDimensions(){
        this.setState({windowWidth: window.innerWidth, windowHeight: window.innerHeight})
    }
    render() {
        const {heading} = this.props;
        let sm = this.state.windowWidth < 600;
        return (
            <div className="relative lg:overflow-hidden min-h-screen w-screen bg-primary notFixed homeNotFixed md:mt-0">
                <div className="absolute md:mt-40 z-40 w-full h-full left-0 top-0">
                    <div className="text-white pageGrid px-2 mx-auto md:h-full flex w-full relative items-center flex-stretch">
                        {heading&&
                        <div className="text-left lg:w-8/12 md:w-12/12 md:pl-10 lg:pl-0 mt-20 mb-20 md:mt-20">
                            <h2 className="text-white text-2xl md:text-3xl lg:text-4xl font-bold leading-tight text-secondary mb-8">{this.props.heading}</h2>
                            <p className="lg:text-3xl text-2xl leading-tight">{this.props.description}</p>
                        </div>
                        }
                        <div className="w-6/12"></div>
                    </div>
                </div>
                    <div className="absolute w-full h-full z-30 opacity-50 bg-black "></div>
                    <div className="z-20 md:min-h-screen w-screen p-56 right-0 bottom-0" id="video" style={{width: this.state.windowWidth, minWidth: '1000px', marginLeft: sm? '0px': 0}}>
                        {sm?
                        <Video poster={MobilePoster} autoPlay={true} ref={makeMuted}><Video.Source src={HomeVideoMobile} type="video/mp4" /></Video> 
                        :
                        <Video poster={Poster} autoPlay={true} ref={makeMuted}><Video.Source src={HomeVideo} type="video/mp4" /></Video>   
                        }  
                    </div>
                    
            </div>
        )
    }
}

import React, { Component } from 'react';
import axios from 'axios';
import { connect } from "react-redux";
import { storeLanguages, storeMenu, storeLabels, setLanguage } from "../../actions/";
import Html from 'react-html-parser';
import Footer from '../Footer/Footer'
const api = process.env.REACT_APP_GRAV_BASE_URL;
function mapDispatchToProps(dispatch) {
	return {
        storeLanguages: languages => dispatch(storeLanguages(languages)),
        storeMenu: menu => dispatch(storeMenu(menu)),
        storeLabels: labels => dispatch(storeLabels(labels)),
        setLanguage: language => dispatch(setLanguage(language)),
	};
}
export class Conditions extends Component {
        constructor(props) {
            super(props);
            this.state = {
                labels: {},
                header: {},
                content: "",
            }
        }
        
        componentDidMount() {
            window.scrollTo(0,0)
            this.fetchPage();
        
        }
        fetchPage(){
            let urlId = this.props.match.params.langid;
            this.props.setLanguage(urlId)
            axios.get(`${api}/${urlId}/privacy-policy.json`).then((res)=>{
            this.setState({
                labels: res.data.labels,
                header: res.data.header,
                content: res.data.content,
                
            })
            if(res.data.labels){
                this.props.storeLabels(res.data.labels)
            }
            if(res.data.languages){ this.props.storeLanguages(res.data.languages)}
           
            if(res.data.menu){
                this.props.storeMenu(res.data.menu)
            }
            })
            .catch(error => {
            if (error.response) {
                /*
                * The request was made and the server responded with a
                * status code that falls out of the range of 2xx
                */
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                this.props.history.push('/error/404')
            } else if (error.request) {
                /*
                * The request was made but no response was received, `error.request`
                * is an instance of XMLHttpRequest in the browser and an instance
                * of http.ClientRequest in Node.js
                */
                this.props.history.push(`/${urlId}/error/unknown-error`)
                console.log(error.request);
            } else {
                // Something happened in setting up the request and triggered an Error
                console.log('Error', error.message);
                this.props.history.push(`/${urlId}/error/unknown-error`)
            }
            console.log(error.config);
            });
        }
    render() {
        return (
            <>
               <div className="md:pt-32 bg-primary text-white block">

                    <div className="py-20">
                        <div className="pageGrid">
                            <h1 className="text-3xl font-bold text-white">{this.state.header.title}</h1>
                        </div>
                    </div>
                </div> 
                <div className="py-20 bg-white">
                    <div className="pageGrid">
                        <div className="max-w-5xl policy">
                            {Html(this.state.content)}
                        </div>
                    </div>
                </div>
                <Footer/>
            </>
        )
    }
}
const connctedConditions = connect(
	null,
	mapDispatchToProps
  )(Conditions);
  
export default connctedConditions;